import React, { useCallback, useEffect } from "react";
import Container from "react-bootstrap/Container";
import Row from "react-bootstrap/Row";
import Col from "react-bootstrap/Col";
import Form from "react-bootstrap/Form";
import useDeviceSize from "hooks/device-size";
import GeneralButton from "components/buttons/general-button.jsx";
import useApi from "hooks/api.js";
import RateSelection from "./rate-selection/rate-selection.jsx";
import MonthlyConsumptionInput from "./monthly-consumption.jsx";
import EquipmentSelection from "./results/equipment-selection.jsx";

function SystemDesign({
  formData,
  setFieldValue,
  setPVWattsData,
  rateStructures,
  showButtons,
  showFullInstructions,
  showEquipment,
  resultsPage,
  goToNextStep,
  goToPreviousStep,
  customEmbedConfig,
  showSolar
}) {
  const { isMobile } = useDeviceSize();
  const api = useApi();

  const { makeSolarOptional, skipRateSelectionPage, showBackButton } = customEmbedConfig;

  const handleSubmit = useCallback(() => {
    if (!formData.oldRateStructure) {
      setFieldValue("oldRateStructure", rateStructures[0].label);
    }
    if (!formData.newRateStructure) {
      setFieldValue("newRateStructure", formData.oldRateStructure ? formData.oldRateStructure : rateStructures[0].label);
    }

    const { systemLossPercentage, latitude, longitude } = formData;

    api
      .post("/solar-site-data/get-pvwatts-data", {
        systemLossPercentage,
        latitude,
        longitude,
      })
      .then((response) => {
        const responseData = response.data.data;
        setPVWattsData(responseData);
        goToNextStep({ skipFade: skipRateSelectionPage });
      })
      .catch((e) => console.log(e + "\nError in fetch"));
  }, [api, formData, setFieldValue, setPVWattsData, goToNextStep]);

  useEffect(() => {
    if (skipRateSelectionPage) {
      handleSubmit();
    }
  }, []);

  return (
    <Container style={{ padding: isMobile ? "0px" : "0px 12px" }}>
      <Form noValidate>
        {!resultsPage && makeSolarOptional &&
          <>
            <div className="padding" />
            <Row>
              <h2 style={{ fontWeight: 600 }}>
                Briggs & Stratton Backup Estimator
              </h2>
            </Row>
            <Row>
              <h3 style={{ fontWeight: 250 }}>
                Select an electric rate structure to estimate your kWh usage, then adjust as desired
              </h3>
            </Row>
          </>
        }
        <div>
          <div className="padding" />
          {showEquipment && (
            <EquipmentSelection
              formData={formData}
              setFieldValue={setFieldValue}
              showFullInstructions={showFullInstructions}
              customEmbedConfig={customEmbedConfig}
              showSolar={showSolar}
            />
          )}
          <Row>
            <Col xl={makeSolarOptional ? 6 : 12}>
              <RateSelection
                rateStructures={rateStructures}
                formData={formData}
                setFieldValue={setFieldValue}
                customEmbedConfig={customEmbedConfig}
              />
            </Col>
            <Col xl={makeSolarOptional ? 6 : 12}>
              <MonthlyConsumptionInput
                formData={formData}
                setFieldValue={setFieldValue}
                showLabel={true}
                makeSolarOptional={makeSolarOptional}
              />
            </Col>
          </Row>
        </div>
        {showButtons && (
            <Row
              style={{
                marginTop: "60px",
              }}
            >
              <Col xl={4}>
                {showBackButton && (
                  <GeneralButton
                    text="Go Back"
                    variant="secondary"
                    onClick={goToPreviousStep}
                    fullWidth
                    rounded
                  />
                )}
              </Col>
              <Col xl={4}/>
              <Col xl={4}>
                <GeneralButton
                  text="Estimate Performance"
                  variant={makeSolarOptional ? "success" : "warning"}
                  onClick={handleSubmit}
                  readOnly={!formData.oldRateStructure}
                  fullWidth
                  rounded
                />
              </Col>
            </Row>

        )}
      </Form>
    </Container>
  );
}

export default SystemDesign;
