import React from 'react';
import Form from 'react-bootstrap/Form';
import Row from 'react-bootstrap/Row';
import Col from 'react-bootstrap/Col';
import OverlayTrigger from "react-bootstrap/OverlayTrigger";
import Tooltip from "react-bootstrap/Tooltip";
import CurrencyInput from 'react-currency-input-field';
import useDeviceSize from 'hooks/device-size';
import { questionMarkIcon } from '../../../components/objects/icons.jsx';

function MonthlyConsumptionInput({
  formData,
  setFieldValue,
  readOnly,
  showLabel,
  makeSolarOptional
}) {
  const setMonthConsumptionData = (index, value) => {
    setFieldValue("siteConsumptionData", [
      ...formData.siteConsumptionData.slice(0, index),
      value,
      ...formData.siteConsumptionData.slice(index + 1),
    ]);
  };

  const setAllMonthsConsumptionData = (value) => {
    const updatedConsumptionData = [...formData.siteConsumptionData].map(
      () => value
    );
    setFieldValue("siteConsumptionData",updatedConsumptionData)
  }

  const months = ['January', 'February', 'March', 'April', 'May', 'June', 'July', 'August', 'September', 'October', 'November', 'December'];
  const monthSets = [
    ['January', 'February'],
    ['March', 'April'],
    ['May', 'June'],
    ['July', 'August'],
    ['September', 'October'],
    ['November', 'December']
  ];

  const renderTooltip = props => (
    <Tooltip {...props}> A default monthly kWh has been set based on your current electric bill. You can change these values with exact info from your electric bill. </Tooltip>
  );

  const { isDesktop } = useDeviceSize();
  return (
    makeSolarOptional ? 
    <div
      style={{
        marginTop: "18px"
      }}>
      {showLabel && (
        <Form.Label
        htmlFor={'average-monthly-consumption'}
        className='big-font'> Estimated Monthly kWh:
        <span>
          {
            <div
              style={{
                display: 'inline',
                marginLeft: '6px',
              }}>
                <OverlayTrigger placement={"top"} overlay={renderTooltip}>
                  { questionMarkIcon }
                </OverlayTrigger>
            </div> 
          }
        </span>
      </Form.Label>
      )}

        <CurrencyInput
          style={{
            display: 'block',
            alignItems: 'left',
            textAlign: 'left',
          }}
          id={'average-monthly-consumption'}
          className='form-control'
          placeholder='kWh'
          value={isNaN(formData.monthlyConsumptionKwh) ? 0 : formData.monthlyConsumptionKwh}
          decimalsLimit={2}
          allowNegativeValue={false}
          onValueChange={value => {
            setAllMonthsConsumptionData(isNaN(value) ? 0 : Number(value));
            setFieldValue("monthlyConsumptionKwh",isNaN(value) ? 0 : Number(value))
          }}
          disabled={!formData.oldRateStructure}
        />
    </div> :
    <div
      style={{
        marginTop: "30px"
      }}>
      {showLabel && (
        <Row>
          {
            readOnly ?
            (
              <div>
                <p className='instructions'>
                  Monthly electricity consumption (kWh):
                </p>
              </div>
            ) :
            (
              makeSolarOptional ?
              <div>
                <p className='instructions'>
                  Estimated Monthly kWh
                  <span>
                  {
                    readOnly !== true ?
                    <div
                      style={{
                        display: 'inline',
                        marginLeft: '6px',
                      }}>
                        <OverlayTrigger placement="right" overlay={renderTooltip}>
                          { questionMarkIcon }
                        </OverlayTrigger>
                    </div> :
                    <div/>
                  }
                </span>
                </p>
              </div> :
              formData.oldRateStructure && <div>
                <p className='instructions'>
                  Enter Monthly kWh (optional)
                  <span>
                  {
                    readOnly !== true ?
                    <div
                      style={{
                        display: 'inline',
                        marginLeft: '6px',
                      }}>
                        <OverlayTrigger placement="right" overlay={renderTooltip}>
                          { questionMarkIcon }
                        </OverlayTrigger>
                    </div> :
                    <div/>
                  }
                </span>
                </p>
              </div>
            )
          }
        </Row>
      )}
      {
        makeSolarOptional ?
        <CurrencyInput
          style={{
            display: 'block',
            alignItems: 'left',
            textAlign: 'left',
          }}
          id={'average-monthly-consumption'}
          className='form-control'
          placeholder='kWh'
          value={isNaN(formData.monthlyConsumptionKwh) ? 0 : formData.monthlyConsumptionKwh}
          decimalsLimit={2}
          allowNegativeValue={false}
          onValueChange={value => {
            setAllMonthsConsumptionData(isNaN(value) ? 0 : Number(value));        
            //setFieldValue("monthlyConsumptionKwh",isNaN(value) ? 0 : Number(value));
          }}
          disabled={!formData.oldRateStructure}
        /> :

        isDesktop ?
          formData.oldRateStructure && <Row>
            {
              months.map((month, index) =>
                <Col 
                  md={1}
                  key={`${month}-column`}>
                  <label
                    id={`${month.toLowerCase()}-consumption-label`}
                    style={{
                      display: 'flex',
                      alignItems: 'center',
                      justifyContent: 'center'
                    }}
                    htmlFor={`${month.toLowerCase()}-consumption`}> { month}
                  </label>
                  <CurrencyInput
                    style={{
                      fontSize: '14px',
                      display: 'block',
                      alignItems: 'center',
                      textAlign: 'center',
                    }}
                    id={`${month.toLowerCase()}-consumption`}
                    className='form-control'
                    placeholder='kWh'
                    onFocus={(e) => e.target.select()}
                    onBlur={(e) => e.target.value ? {} : setMonthConsumptionData(index, 0)}
                    value={isNaN(formData.siteConsumptionData[index]) ? 0 : formData.siteConsumptionData[index]}
                    decimalsLimit={2}
                    allowNegativeValue={false}
                    onValueChange={value => setMonthConsumptionData(index, isNaN(value) ? 0 : Number(value))}
                  />
                </Col>
              )
            }
          </Row> :
          formData.oldRateStructure && monthSets.map((monthSet, monthSetIndex) => (
            <Row 
              key={`consumption-month-row-${monthSetIndex}`}
              className={'justify-content-center'}>
              {
                monthSet.map((month, monthIndex) => {
                  const dataIndex = (monthSetIndex * 2) + monthIndex;
                  
                  return (
                    <Col
                      xs={6}
                      key={`${month}-column`}>
                      <label
                        id={`${month.toLowerCase()}-consumption-label`}
                        style={{
                          display: 'flex',
                          alignItems: 'center',
                          justifyContent: 'center'
                        }}
                        htmlFor={`${month.toLowerCase()}-consumption`}> { month}
                      </label>
                      <CurrencyInput
                        style={{
                          fontSize: '14px',
                          display: 'block',
                          alignItems: 'center',
                          textAlign: 'center',
                          width: '100%'
                        }}
                        placeholder='kWh'
                        onFocus={() => setMonthConsumptionData(dataIndex, '')}
                        onBlur={(e) => e.target.value ? {} : setMonthConsumptionData(dataIndex, 0)}
                        value={isNaN(formData.siteConsumptionData[dataIndex]) ? '' : formData.siteConsumptionData[dataIndex]}
                        decimalsLimit={2}
                        allowNegativeValue={false}
                        onValueChange={value => setMonthConsumptionData(dataIndex, isNaN(value) ? 0 : Number(value))}/>
                    </Col>
                  )
                })
              }
            </Row>
          ))
      }
    </div>
  );
}

export default MonthlyConsumptionInput;