import React from "react";
import Col from "react-bootstrap/Col";
import Form from "react-bootstrap/Form";
import OverlayTrigger from "react-bootstrap/OverlayTrigger";
import Tooltip from "react-bootstrap/Tooltip";
import { htmlStringToDisplay } from "utils/lib.js";
import { questionMarkIcon } from "../objects/icons.jsx";

function TextInput({
  style,
  htmlName,
  displayName,
  fieldName,
  tooltip,
  tooltipPlacement,
  placeholder,
  value,
  setFieldValue,
  colWidth,
  readOnly,
  invalidMessage,
  required,
  positionLabelAdjacent,
  onBlur,
  onKeyDown,
  validated,
}) {
  const label = displayName || htmlStringToDisplay(htmlName);

  const renderTooltip = (props) => <Tooltip {...props}>{tooltip}</Tooltip>;

  const labelStyle = {
    fontWeight: 600,
    fontSize: '22px'
  }

  return (
    <Form.Group
      as={Col}
      xl={positionLabelAdjacent ? 12 : colWidth}
      style={{
        marginTop: style ? undefined : "20px",
        ...style,
        display: positionLabelAdjacent ? "flex" : "block",
        alignItems: positionLabelAdjacent ? "center" : "stretch",
      }}
    >
      <Form.Label
        htmlFor={htmlName}
        className="big-font"
        style={
          positionLabelAdjacent
            ? {
                ...labelStyle,
                flexShrink: 0,
                whiteSpace: "nowrap",
                marginRight: "8px",
              }
            : labelStyle
        }
      >
        {label + ": "}
        <span>
          {tooltip && readOnly !== true ? (
            <div
              style={{
                display: "inline",
                marginLeft: "6px",
              }}
            >
              <OverlayTrigger 
                placement={tooltipPlacement ? tooltipPlacement : "top"}
                overlay={renderTooltip}>
                {questionMarkIcon}
              </OverlayTrigger>
            </div>
          ) : (
            <div />
          )}
        </span>
      </Form.Label>
      <Form.Control
        id={htmlName}
        type="text"
        placeholder={placeholder ? placeholder : label}
        value={value}
        onChange={(e) => {
          setFieldValue(fieldName, e.target.value);
        }}
        disabled={readOnly === true}
        required={required}
        onBlur={onBlur}
        onKeyDown={onKeyDown}
        isInvalid={validated && !!invalidMessage}
      />
      {validated && invalidMessage && (
        <Form.Control.Feedback type="invalid">
          {invalidMessage}
        </Form.Control.Feedback>
      )}
    </Form.Group>
  );
}

export default TextInput;
