import React from 'react';
import Form from 'react-bootstrap/Form'
import Col from 'react-bootstrap/Col'
import OverlayTrigger from "react-bootstrap/OverlayTrigger";
import Tooltip from "react-bootstrap/Tooltip";
import { htmlStringToDisplay } from 'utils/lib.js';
import { questionMarkIcon } from '../objects/icons.jsx';

function DropdownInput({
  style,
  htmlName,
  displayName,
  fieldName,
  placeholder,
  tooltip,
  tooltipPlacement,
  options,
  value,
  setFieldValue,
  nullifyField,
  readOnly,
  showWhen,
  colWidth,
  onChange,
}) {
  const label = displayName
    ? displayName
    : htmlName
      ? htmlStringToDisplay(htmlName)
      : null

  const renderTooltip = props => (
    <Tooltip {...props}>{ tooltip }</Tooltip>
  );

  return showWhen !== false ? (
    <Form.Group
      as={Col}
      xl={colWidth}
      style={style ? style : {marginTop: '20px'}}>
      {label &&
        <Form.Label
          htmlFor={htmlName}
          className='big-font'> { label + ': ' }
          <span>
            {
              tooltip && readOnly !== true ?
              <div
                style={{
                  display: 'inline',
                  marginLeft: '6px',
                }}>
                  <OverlayTrigger placement={tooltipPlacement ? tooltipPlacement : "top"} overlay={renderTooltip}>
                    { questionMarkIcon }
                  </OverlayTrigger>
              </div> :
              <div/>
            }
          </span>
        </Form.Label>
      }
      <select 
        className='form-control'
        id={htmlName}
        value={value}
        onChange={onChange || (e => {
          setFieldValue(fieldName,e.target.value);
          if (nullifyField) { return setFieldValue(nullifyField,''); }
        })}
        disabled={readOnly===true}>
        {(placeholder || displayName || label) &&
          <option value="" disabled> {(placeholder ? placeholder : "Select " + (displayName || label.toLowerCase()) )}</option>
        }
        {
          options.map((option) => (
            <option
              key={option.id}
              value={option.id}>{option.label || option.model}
            </option>
          ))
        }
      </select>
    </Form.Group>
  ) : null;
}

export default DropdownInput;