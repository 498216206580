import { isEmpty } from "utils/lib";

export function validateProjectBasicsForm(formData) {
  const errors = {};

  if (isEmpty(formData.address)) {
    errors.address = "Please enter an address";
  }

  return errors;
}

export async function geocodeAddressAndGetSiteData(api, address) {
  try {
    const response = await api.post(
      "/solar-site-data/fetch-comprehensive-data",
      { address }
    );

    if (response.data.error) {
      throw new Error(response.data.error.message || "Geocoding failed");
    }

    return response.data;
  } catch (e) {
    console.error("Error geocoding address", e.message);
    throw e;
  }
}
