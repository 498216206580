import React, { useState } from 'react';
import Form from 'react-bootstrap/Form';
import Row from 'react-bootstrap/Row';
import Col from 'react-bootstrap/Col';
import OverlayTrigger from "react-bootstrap/OverlayTrigger";
import Tooltip from "react-bootstrap/Tooltip";
import { default as ThirdPartyCurrencyInput } from 'react-currency-input-field';
import { htmlStringToDisplay } from 'utils/lib.js';
import { questionMarkIcon } from '../objects/icons.jsx';
import CustomInputLabel from './custom-input-label.jsx';

export const CurrencyInput = ({
  style,
  htmlName,
  displayName,
  fieldName,
  placeholder,
  tooltip,
  tooltipPlacement,
  value,
  prefix,
  suffix,
  setFieldValue,
  readOnly,
  invalidMessage,
  required,
  labelPosition,
  labelComponent,
  labelStyle
}) => {
  const label = displayName || htmlStringToDisplay(htmlName);

  const renderTooltip = props => (
    <Tooltip {...props}>{ tooltip }</Tooltip>
  );

  return (
    <Form.Group
      as={Row}
      style={style ? style : {marginTop: '20px'}}>
      {labelComponent 
        ? <CustomInputLabel 
            htmlFor={htmlName}
            label={label}
            labelComponent={labelComponent}
            labelStyle={labelStyle}
          />
        : <Form.Label
          column
          xs={labelPosition === 'adjacent' ? 6 : 12}
          htmlFor={htmlName}
          style={labelStyle}
        >
          { label + ': ' }
          <span>
            {
              tooltip && readOnly !== true ?
              <div
                style={{
                  display: 'inline',
                  marginLeft: '6px',
                }}>
                  <OverlayTrigger placement={tooltipPlacement ? tooltipPlacement : "top"} overlay={renderTooltip}>
                    { questionMarkIcon }
                  </OverlayTrigger>
              </div> :
              null
            }
          </span>
        </Form.Label>
      }
      <Col 
        xs={labelPosition === 'adjacent' ? 6 : 12}>
        <ThirdPartyCurrencyInput
          id={htmlName}
          className={`form-control`}
          style={
            labelPosition === 'adjacent' ? {
              marginTop: '5px',
              display: 'block',
              alignItems: 'center',
              textAlign: 'center',
              fontSize: '22px',
            } : {}
          }
          name={fieldName}
          placeholder={placeholder}
          prefix={prefix}
          suffix={suffix}
          value={value || 0}
          decimalsLimit={2}
          allowNegativeValue={false}
          onValueChange={(value, name) => {
            setFieldValue(name, parseInt(value))
          }}
          disabled={readOnly}
          required={required}/>
        <Form.Control.Feedback type="invalid">
          { invalidMessage ? invalidMessage : `Please enter a ${label}`}
        </Form.Control.Feedback>
      </Col>
    </Form.Group>
  );
};

export default CurrencyInput;