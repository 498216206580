import axios from "axios";
import { serverAddress } from "configs/env";
import { useErrorNotification } from "hooks/error-notification";
import { useMemo } from "react";

const useApi = () => {
  const { notifyError } = useErrorNotification();

  return useMemo(() => {
    const createApi = (options = {}) => {
      const { useBaseUrl = true } = options;

      const api = axios.create({
        baseURL: useBaseUrl ? serverAddress : undefined,
        withCredentials: useBaseUrl,
        timeout: 5000,
        ...options,
      });

      api.interceptors.response.use(
        (response) => response,
        (error) => {
          let errorMessage;
          if (error.response) {
            console.log(`wtfError (api hook): ${error.response}`);
            errorMessage =
              error.response?.data?.error?.message || "An unknown error occurred";
          } else if (error.request) {
            errorMessage = "API Request Error: No response received";
          } else {
            errorMessage = `API request setup error: ${error.message}`;
          }

          notifyError(errorMessage);

          return Promise.reject(error);
        }
      );

      return api;
    };

    const defaultApi = createApi();

    defaultApi.createCustom = createApi;

    return defaultApi;
  }, [notifyError])
};

export default useApi;
