import React, { useState } from 'react';
import Container from 'react-bootstrap/Container';
import Row from 'react-bootstrap/Row';
import Col from 'react-bootstrap/Col';
import Form from 'react-bootstrap/Form';
import TextInput from 'components/inputs/text.jsx';
import CurrencyInput from 'components/inputs/currency.jsx';
import { isEmpty } from 'utils/lib.js';
import MapWidget from './google-map-widget';
import useDeviceSize from 'hooks/device-size.jsx';
import GeneralButton from 'components/buttons/general-button.jsx';
import { geocodeAddressAndGetSiteData, validateProjectBasicsForm } from './project-basics-utils.js';
import useApi from 'hooks/api';
import { useParams } from 'react-router-dom';

function ProjectBasics({
  formData,
  setFormData,
  setFieldValue,
  setRateStructures,
  goToNextStep,
  customEmbedConfig
}) {
  const [validated, setValidated] = useState(false);
  const [address, setAddress] = useState(undefined);
  const [errors, setErrors] = useState({});
  const { isMobile, isDesktop } = useDeviceSize();
  const api = useApi();
  const { teamCode: usingCustomEmbed } = useParams();
  const makeSolarOptional = customEmbedConfig.makeSolarOptional;
  const startingMapAddress = formData.zipCode;
  
  const formPassesValidation = () => {
    setValidated(true);

    const validationErrors = validateProjectBasicsForm(formData);
    setErrors(validationErrors);

    return isEmpty(validationErrors);
  };

  const handleAddressChange = ({ newAddress, options }) => {
    if (options?.centerMap) {
      setAddress(newAddress);
      setFieldValue("address", newAddress);
    } else {
      setFieldValue("address", newAddress);
    }
  }

  const handleAddressEnterKey = (event) => {
    if (event.key === "Enter") {
      setAddress(formData.address);
    }
  }

  const handleSubmit = async () => {
    const valid = await formPassesValidation();
  
    if (!valid) {
      return
    }

    //Extra save to show the $200 value right when they click
    if (!formData.monthlyElectricBill) {
      setFieldValue("monthlyElectricBill", 200);
    }

    geocodeAddressAndGetSiteData(api, formData.address)
      .then((response) => {
        const { geocodeData, rateStructures } = response.data;
        const formDataToUpdate = { ...formData, ...geocodeData };

        setRateStructures(rateStructures);
        setFormData(formDataToUpdate);
        //Inelegantly saving again because we're using setFormData instead of setField Value
        if (!formData.monthlyElectricBill) {
          setFieldValue("monthlyElectricBill", 200);
        }
        
        goToNextStep();
      })
      .catch((e) => console.log(`geocodeAddressAndGetSiteData error: ${e}`));
  };

  return (
    <Container style={{ padding: isMobile ? "0px" : "0px 12px" }}>
      <Form noValidate validated={validated}>
        {!usingCustomEmbed &&
          <>
            <div className="padding" />
            <Row>
              <h2 style={{ fontWeight: 600 }}>
                PVBatts Quick Designer
              </h2>
            </Row>
          </>
        }
        {makeSolarOptional &&
          <>
            <div className="padding" />
            <Row>
              <h2 style={{ fontWeight: 600 }}>
                Welcome to the Briggs & Stratton Backup Estimator
              </h2>
            </Row>
            <Row>
              <h3 style={{ fontWeight: 250 }}>
                To get started, enter your address and monthly electric bill below
              </h3>
            </Row>
            <div className="padding" />
          </>
        }
        <Row
          style={{
            marginTop: "0px",
          }}
          className="align-items-start"
        >
          <Col xl={6} md={12}>
            <TextInput
              style={{
                marginTop: "7px",
                marginBottom: "16px",
                fontWeight: 600,
              }}
              htmlName="address"
              fieldName="address"
              tooltip={
                makeSolarOptional ? 
                "Your address is used to find your local utility rates" :
                "PVBatts uses solar production data for your address to calculate the expected power output of your solar modules."
              }
              tooltipPlacement={customEmbedConfig ? "right" : "top"}
              placeholder={"e.g. 123 Main St"}
              value={formData.address || ""}
              setFieldValue={setFieldValue}
              required={true}
              invalidMessage={errors?.address}
              onBlur={() => setAddress(formData.address)}
              onKeyDown={handleAddressEnterKey}
              validated={validated}
            />
          </Col>
          <Col xl={12} md={12} className="order-lg-3">
            <MapWidget
              address={address || startingMapAddress}
              updateAddress={handleAddressChange}
            />
          </Col>
          <Col xl={6} md={12} className="order-lg-2">
            <CurrencyInput
              htmlName="electric-bill"
              displayName="Electric Bill"
              style={{
                marginTop: "0px",
                marginBottom: "16px",
                fontWeight: 600,
                fontSize: '22px'
              }}
              placeholder="e.g. $200"
              prefix="$"
              fieldName="monthlyElectricBill"
              tooltip={
                makeSolarOptional ?
                "Your electric bill is used to estimate your average monthly electricity consumption (kWh). You'll be able to update your kWh on the next page." :
                "Your electric bill is used to estimate average electricity consumption, which is subtracted from solar power production to calculate the amount left over for storage."
              }
              tooltipPlacement={customEmbedConfig ? "right" : "top"}
              value={formData.monthlyElectricBill}
              setFieldValue={setFieldValue}
              decimalsLimit={2}
              allowNegativeValue={false}
            />
          </Col>
        </Row>
        <Row className="mt-3">
          <Col xl={8} />
          <Col className={!isDesktop && "pb-3"} xl={4}>
            <GeneralButton
              text="Continue"
              onClick={handleSubmit}
              variant={makeSolarOptional ? "success" : "warning"}
              fullWidth
              rounded
            />
          </Col>
        </Row>
      </Form>
    </Container>
  );
}

export default ProjectBasics;


