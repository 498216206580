import React, { Suspense } from 'react';
import { Container } from 'react-bootstrap';
import ProjectBasics from './components/project-basics/project-basics';
import QuickDesignResults from './components/results/results';
import useQuickDesigner from 'hooks/quick-designer';
import SystemDesign from './components/system-design';

function QuickDesignerPage() {
  const {
    setFieldValue,
    formData,
    setFormData,
    pvWattsData,
    setPVWattsData,
    resultsGridRef,
    currentStep,
    goToNextStep,
    goToPreviousStep,
    rateStructures,
    setRateStructures,
    showComponent,
    customEmbedConfig,
    isLoaded
  } = useQuickDesigner();

  return (
    isLoaded && <Container>
      <Suspense fallback={<div>Loading...</div>}>
        <div
          style={{
            opacity: showComponent ? 1 : 0,
            transition: "opacity 0.3s ease-in-out",
          }}
        >
          {currentStep === 0 && (
            <div>
              <ProjectBasics
                formData={formData}
                setFormData={setFormData}
                setFieldValue={setFieldValue}
                setRateStructures={setRateStructures}
                goToNextStep={goToNextStep}
                setPVWattsData={setPVWattsData}
                customEmbedConfig={customEmbedConfig}
              />
            </div>
          )}
          {currentStep === 1 && (
            <div>
              <SystemDesign
                formData={formData}
                setFieldValue={setFieldValue}
                rateStructures={rateStructures}
                showFullInstructions={true}
                showEquipment={false}
                showButtons={true}
                setPVWattsData={setPVWattsData}
                goToNextStep={goToNextStep}
                goToPreviousStep={goToPreviousStep}
                customEmbedConfig={customEmbedConfig}
              />
            </div>
          )}
          {currentStep >= 2 && (
            <div>
              <QuickDesignResults
                resultsGridRef={resultsGridRef}
                formData={formData}
                rateStructures={rateStructures}
                setFieldValue={setFieldValue}
                pvWattsData={pvWattsData}
                goToPreviousStep={goToPreviousStep}
                customEmbedConfig={customEmbedConfig}
              />
            </div>
          )}
        </div>
      </Suspense>
    </Container>
  )
};

export default QuickDesignerPage;
