import React from 'react';
import { useMemo, } from 'react';
import { pvWattsFields } from '../../utils/constants';
import {
  colorSchemeDarkWarm,
  themeAlpine,
} from "ag-grid-community";
import { AgGridReact } from "ag-grid-react";

const gridTheme = themeAlpine.withPart(colorSchemeDarkWarm)

function ResultsTable({id, gridRef, label, rowData, showWhen}) {
  const valueFormatter = (params) => { 
    if (String(params.value).includes('%')) { return params.value; }
    else { return Math.round(params.value) }
  }

  const kWhFormatter = (params) => {
    return params.node.rowIndex === 13
      ? Math.round(params.value) + " kWh"
      : valueFormatter(params)
  }

  const dollarFormatter = (params) => {
    if (String(params.value).includes('%')) { return params.value; }
    else { return "$" + Math.round(params.value) }
  }

  const defaultColDef={
    resizable: true,
    sortable: true,
    wrapText: true,
    autoHeight: true,
    flex: 1,
    cellStyle: () => ({
      display: "flex",
      alignItems: "center"
    })
  };

  const columnDefs = useMemo(
    () => [
      {
        field: 'month',
        headerName: 'Month',
        flex: 0,
        width: 110,
        wrapHeaderText: true,
        wrapText: true
      },
      {
        headerName: 'Site Consumption (kWh)',
        cellEditor: 'agTextCellEditor',
        valueGetter: params => {
          return params.data.siteConsumption;
        },
        wrapHeaderText: true,
        minWidth: 60,
        valueFormatter: kWhFormatter,
      },
      {
        valueGetter: params => {
          return params.data.genDays + (params.node.rowIndex === 13 ? ' days' : '');
        },
        headerName: 'Days of Grid Use',
        minWidth: 45,
        wrapHeaderText: true
      },
      {
        field: pvWattsFields.SolarOffset,
        headerName: 'Solar Production (kWh)',
        valueFormatter: kWhFormatter,
        minWidth: 55,
        wrapHeaderText: true
      },
      {
        field: pvWattsFields.SystemConsumption,
        headerName: 'Solar Consumption (kWh)',
        valueFormatter: kWhFormatter,
        minWidth: 70,
        wrapHeaderText: true
      },
      {
        field: pvWattsFields.GridConsumption,
        headerName: 'Grid Consumption (kWh)',
        valueFormatter: kWhFormatter,
        minWidth: 65,
        wrapHeaderText: true
      },
      {
        field: pvWattsFields.NetGridExport,
        headerName: 'Net Grid Export (kWh)',
        valueFormatter: kWhFormatter,
        width: 60,
        wrapHeaderText: true
      },
      {
        field: pvWattsFields.NetDollarSavings,
        headerName: 'Net Savings',
        valueFormatter: dollarFormatter,
        width: 60,
        wrapHeaderText: true
      },
      {
        field: pvWattsFields.DollarSellback,
        headerName: 'Sellback Income',
        valueFormatter: dollarFormatter,
        width: 60,
        wrapHeaderText: true
      },
    ],
    []
  );


  if (rowData.length === undefined || rowData.length < 1) {
    return;
  } else {
    return (
      <div
        id={'advanced-design-report'}
        className='section'
        style={{
          display: showWhen ? 'block' : 'none'
        }}>
        <label
          className='sub-header'
          htmlFor={id}> {label + ':'}
        </label>
        <div
          id={id}
          style={{
            height: '100%',
            width: '100%',
            marginTop: '20px',
            display: 'block'
          }}>
          <AgGridReact
            ref={gridRef}
            id='results-table'
            rowData={rowData}
            columnDefs={columnDefs}
            defaultColDef={defaultColDef}
            domLayout='autoHeight'
            headerHeight={60}
            theme={gridTheme}
          />
        </div>
      </div>
    );
  }
}

export default ResultsTable;