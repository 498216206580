import React, { useState, useMemo } from 'react';
import Container from 'react-bootstrap/Container';
import Row from 'react-bootstrap/Row';
import DropdownInput from 'components/inputs/dropdown.jsx';
import { buildRateNamesArray, getRateStructureData, getUniqueRateNames, fetchElectricRateAndEstimateUsage } from './rate-selection-utils.js';
import SingleCheckbox from 'components/inputs/single-checkbox.jsx';
import { NET_METERING_TOOLTIP_TEXT } from './rate-selection-constants.js';
import Col from 'react-bootstrap/esm/Col.js';
import { useRefs } from 'contexts/refs-context';
import useDeviceSize from 'hooks/device-size';
import useApi from 'hooks/api';
import OptionalSolarWidget from '../../../../components/widgets/optional-solar.jsx'

function RateSelection({
  rateStructures,
  formData,
  setFieldValue,
  resultsPage,
  customEmbedConfig
}) {
  const api = useApi();
  const { 
    makeSolarOptional,
  } = customEmbedConfig;
  const { refs } = useRefs();
  const { isMobile } = useDeviceSize();
  const [netMeteringEnabled, setNetMeteringEnabled] = useState(formData.netMeteringEnabled || false);
  const [gridChargeDisabled, setGridChargeDisabled] = useState(formData.gridChargeDisabled || false);

  const rateNames = buildRateNamesArray(rateStructures);
  const uniqueRateNames = getUniqueRateNames(rateNames);

  const handleRateSelectChange = async (e) => {
    const rateStructureLabel = e.target.value;
    if (e.target.value) {
      const selectedRateStructureData = getRateStructureData(
        rateStructureLabel,
        rateStructures
      );
      setFieldValue({
        oldRateStructure: rateStructureLabel,
        newRateStructure: rateStructureLabel
      });
      fetchElectricRateAndEstimateUsage(api, selectedRateStructureData, formData, setFieldValue);
    }
    else {
      return setFieldValue({
        oldRateStructure: "",
        newRateStructure: ""
      })
    }
  }

  return (
    <Row>
      <DropdownInput
        htmlName="rate-structure"
        displayName="Electric Rate Structure"
        fieldName="oldRateStructure"
        tooltip={`Select your current utility rate structure to see comparative data.${resultsPage ? "" : " If you are unsure of your current utility rate, you can leave this blank."}`}
        tooltipPlacement={"right"}
        value={formData.oldRateStructure}
        options={uniqueRateNames}
        onChange={(newValue) => {
          handleRateSelectChange(newValue)
        }}
        colWidth={12}
      />
      {makeSolarOptional && resultsPage ? (
          <OptionalSolarWidget
            formData={formData}
            setFieldValue={setFieldValue}
            refs={refs}
            customEmbedConfig={customEmbedConfig}
          />
        ) :
        resultsPage ?
        (
          <Row>
              <Col xs={isMobile ? 12 : 6} />
              <Col style={{ paddingLeft: "24px" }} xs={isMobile ? 12: 6}>
                <SingleCheckbox
                    id="disable-grid-charge-checkbox"
                    label="Disable Battery Charge from Grid"
                    checked={gridChargeDisabled}
                    onChange={(newValue) => {
                      setGridChargeDisabled(newValue);
                      setFieldValue("gridChargeDisabled", newValue);
                    }}
                  />
                <SingleCheckbox
                  id="net-metering-checkbox"
                  label="Use Net Metering Rates"
                  checked={netMeteringEnabled}
                  onChange={(newValue) => {
                    setNetMeteringEnabled(newValue);
                    setFieldValue("netMeteringEnabled", newValue);
                  }}
                  tooltip={NET_METERING_TOOLTIP_TEXT}
                />
              </Col>
          </Row>
                  
        ) : <></>
      }

    </Row>
  );
}

export default RateSelection;
