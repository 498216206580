export const buildRateNamesArray = (rateStructures) => {
  return rateStructures.map((datum, index) => {
    return {
      label: `${datum.utility} - ${datum.name}`,
      id: datum.label,
      utility: datum.utility,
      name: datum.name,
    };
  });
}

export const getUniqueRateNames = (rateNames) => {
  const uniqueRateNames = [];

  rateNames.forEach((rateName) => {
    if (uniqueRateNames.length === 0 || !uniqueRateNames.find((uniqueRateName) => uniqueRateName.label === rateName.label)) {
      uniqueRateNames.push(rateName);
    }
  });

  return uniqueRateNames;
}

export const getRateStructureData = (rateStructureLabel, rateStructures) => {
  return rateStructures?.find(
    (structure) => structure.label === rateStructureLabel
  );
}

export const isTouRateSelected = (rateStructureData) => {
  const regex = /tou|time[- ]?of[- ]?use/i; // match any instances of ["tou", "time of use", "time-of-use"], case insensitive
  return regex.test(rateStructureData.name);
}

export async function fetchElectricRateAndEstimateUsage(api, selectedRateStructureData, formData, setFieldValue) {
  try {
    api.post(
      "/solar-site-data/estimate-overall-electric-rate",
      { 
        rateStructure: selectedRateStructureData,
        monthlyElectricBill: formData.monthlyElectricBill
      }
    ).then(response => {
      if (response.data.error) {
        throw new Error(response.data.error.message || "API Error");
      }
      else {
        const estimatedOverallElectricRate = response.data.data;
        const touRateSelected = isTouRateSelected(selectedRateStructureData);
        const monthlyConsumptionKwh = formData.monthlyElectricBill
          ? Math.round(formData.monthlyElectricBill / estimatedOverallElectricRate, 2)
          : 1550;
        const updatedConsumptionData = [...formData.siteConsumptionData].map(
          () => monthlyConsumptionKwh
        );
        return setFieldValue({
          averageBillPerKwh: estimatedOverallElectricRate,
          monthlyConsumptionKwh: monthlyConsumptionKwh,
          siteConsumptionData: updatedConsumptionData,
          touRateSelected
        });
      }

      
    });
  } catch (e) {
    console.error("Other Error: ", e.message);
    throw e;
  }
}